var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let FailPage = class FailPage extends Vue {
    constructor() {
        super(...arguments);
        this.searchLoading = false;
        this.result = '';
        this.pipeline = '';
    }
    mounted() {
        mixpanelTracking('welcomescreen2.0: ops foi visualizado');
    }
    async nextSearch() {
        this.searchLoading = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.resetSearchQuery();
        const sectors = this.dataToSearch.sectors.length
            ? [{ main_cnae_group_code: this.dataToSearch.sectors }]
            : [];
        const query = {
            search: {
                match_either: sectors,
                match: {
                    //@ts-ignore
                    'decision_makers_lkd_m5.department': [...this.dataToSearch.decisors],
                    ds_situacao_cnpj: ['ATIVA'],
                },
                offset: 0,
                return_size: 10,
                where: {
                    //@ts-ignore
                    revenue_a32_company_code: [
                        this.dataToSearch.company_size.searchBilling[0],
                        this.dataToSearch.company_size.searchBilling[1],
                    ],
                    //@ts-ignore
                    total_employees_r2016_company: [
                        this.dataToSearch.company_size.searchEmployees[0],
                        this.dataToSearch.company_size.searchEmployees[1],
                    ],
                },
                wherenot: {},
                sort_by: { quality_data_score: 'desc' },
            },
            eliminate_cnpjs_already_exported_in_account: false,
            special_filter: false,
            origin: 'welcome_screen',
        };
        mixpanelTracking('welcomescreen2.0: clicou em continuar mesmo assim');
        //@ts-ignore
        const id = await this.actions.searches.welcomeScreenCreateSearch({ query });
        //@ts-ignore
        await this.actionsUsers.updateDefaultSearch({
            defaultSearch: id,
            use_default_search: true,
        });
        this.searchLoading = false;
        // @ts-ignore
        this.$router.push(`/search/${id}`);
    }
    backToPage() {
        mixpanelTracking('welcomescreen2.0: clicou em Ajustar escolhas');
        return { page: 1 };
    }
};
__decorate([
    Prop({ default: {} })
], FailPage.prototype, "dataToSearch", void 0);
__decorate([
    Emit('backToPage')
], FailPage.prototype, "backToPage", null);
FailPage = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.search,
        actionsUsers: actions.users,
    }), {
        name: 'FailPage',
        components: {},
    }))
], FailPage);
export default FailPage;
