var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import AdmIcon from '@/components/WelcomeScreen/icons/AdmIcon.vue';
import ComercialIcon from '@/components/WelcomeScreen/icons/ComercialIcon.vue';
import FinanceiroIcon from '@/components/WelcomeScreen/icons/FinanceiroIcon.vue';
import OperacionalIcon from '@/components/WelcomeScreen/icons/OperacionalIcon.vue';
import OutrosIcon from '@/components/WelcomeScreen/icons/OutrosIcon.vue';
import RhIcon from '@/components/WelcomeScreen/icons/RhIcon.vue';
import TiIcon from '@/components/WelcomeScreen/icons/TiIcon.vue';
import MarketingIcon from '@/components/WelcomeScreen/icons/MarketingIcon.vue';
import JuridicoIcon from '@/components/WelcomeScreen/icons/JuridicoIcon.vue';
let OptionDepartament = class OptionDepartament extends Vue {
    constructor() {
        super(...arguments);
        this.selecteds = [];
    }
    mounted() {
        this.selecteds = this.decisors;
    }
    handleOptionsDepartmentSelected() {
        const optionAlreadySelected = this.handleOptionIsSelected();
        if (!optionAlreadySelected) {
            this.selecteds.push(this.departament);
            return this.departament;
        }
        this.selecteds = this.selecteds.filter(label => label !== this.departament);
        this.handleOptionIsSelected();
        return this.departament;
    }
    handleOptionIsSelected() {
        return this.selecteds.some(label => label === this.departament);
    }
};
__decorate([
    Prop({ default: () => { } })
], OptionDepartament.prototype, "decisors", void 0);
__decorate([
    Prop({ default: '' })
], OptionDepartament.prototype, "departament", void 0);
__decorate([
    Prop({ default: '' })
], OptionDepartament.prototype, "img", void 0);
__decorate([
    Emit('handleOptionsDepartmentSelected')
], OptionDepartament.prototype, "handleOptionsDepartmentSelected", null);
OptionDepartament = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'OptionDepartament',
        components: {
            AdmIcon,
            ComercialIcon,
            FinanceiroIcon,
            OperacionalIcon,
            OutrosIcon,
            RhIcon,
            TiIcon,
            MarketingIcon,
            JuridicoIcon,
        },
    }))
], OptionDepartament);
export default OptionDepartament;
