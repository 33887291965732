var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
let OptionPortClient = class OptionPortClient extends Vue {
    constructor() {
        super(...arguments);
        this.selected = false;
        this.letItShowModal = false;
    }
    handleClick(name) {
        if (name === 'Customizado' &&
            !this.selectedPorts.some(option => option.name === 'Customizado')) {
            this.openModal();
        }
        else {
            this.toggleSelected(name);
        }
    }
    toggleSelected(name) {
        const existingIndex = this.selectedPorts.findIndex(option => option.name === name);
        if (existingIndex !== -1) {
            this.selectedPorts.splice(existingIndex, 1);
        }
        if (existingIndex == -1 && name != 'Customizado') {
            this.portSelected({
                name: name,
                billing: this.billing,
                //@ts-ignore
                searchBilling: this.searchBilling.split(',').map(Number),
                employees: this.employees,
                searchEmployees: this.handleBasicEmployees(this.employees),
            });
        }
    }
    isSelected(name) {
        return this.selectedPorts.some(selectedPort => selectedPort.name === name);
    }
    handleBasicEmployees(employees) {
        if (employees == '0 a 1') {
            return [0, 1];
        }
        else if (employees == '1 a 19') {
            return [1, 19];
        }
        else if (employees == '10 a 99') {
            return [10, 99];
        }
        else if (employees == '100 a 499') {
            return [100, 499];
        }
        else if (employees == '500+')
            return [0, 10000];
    }
    portSelected(port) {
        return port;
    }
    openModal() {
        this.letItShowModal = true;
        return this.letItShowModal;
    }
};
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "name", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "billing", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "searchBilling", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "employees", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "billingMin", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "billingMax", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "employeesMin", void 0);
__decorate([
    Prop({ default: '' })
], OptionPortClient.prototype, "employeesMax", void 0);
__decorate([
    Prop({ default: [] })
], OptionPortClient.prototype, "selectedPorts", void 0);
__decorate([
    Emit('portSelected')
], OptionPortClient.prototype, "portSelected", null);
__decorate([
    Emit('openModal')
], OptionPortClient.prototype, "openModal", null);
OptionPortClient = __decorate([
    Component(connect({
        name: 'OptionPortClient',
    }))
], OptionPortClient);
export default OptionPortClient;
