export const filters = [
    {
        'Agricultura, pecuária e serviços relacionados': '01',
    },
    {
        'Produção florestal': '02',
    },
    {
        'Pesca e aqüicultura': '03',
    },
    {
        'Extração de carvão mineral': '05',
    },
    {
        'Extração de petróleo e gás natural': '06',
    },
    {
        'Extração de minerais metálicos': '07',
    },
    {
        'Extração de minerais não-metálicos': '08',
    },
    {
        'Atividades de apoio à extração de minerais': '09',
    },
    {
        'Fabricação de produtos alimentícios': '10',
    },
    {
        'Fabricação de bebidas': '11',
    },
    {
        'Fabricação de produtos do fumo': '12',
    },
    {
        'Fabricação de produtos têxteis': '13',
    },
    {
        'Confecção de artigos do vestuário e acessórios': '14',
    },
    {
        'Preparação de couros e fabricação de artefatos de couro, artigos para viagem e calçados': '15',
    },
    {
        'Fabricação de produtos de madeira': '16',
    },
    {
        'Fabricação de celulose, papel e produtos de papel': '17',
    },
    {
        'Impressão e reprodução de gravações': '18',
    },
    {
        'Fabricação de coque, de produtos derivados do petróleo e de biocombustíveis': '19',
    },
    {
        'Fabricação de produtos químicos': '20',
    },
    {
        'Fabricação de produtos farmoquímicos e farmacêuticos': '21',
    },
    {
        'Fabricação de produtos de borracha e de material plástico': '22',
    },
    {
        'Fabricação de produtos de minerais não-metálicos': '23',
    },
    {
        Metalurgia: '24',
    },
    {
        'Fabricação de produtos de metal, exceto máquinas e equipamentos': '25',
    },
    {
        'Fabricação de equipamentos de informática, produtos eletrônicos e ópticos': '26',
    },
    {
        'Fabricação de máquinas, aparelhos e materiais elétricos': '27',
    },
    {
        'Fabricação de máquinas e equipamentos': '28',
    },
    {
        'Fabricação de veículos automotores, reboques e carrocerias': '29',
    },
    {
        'Fabricação de outros equipamentos de transporte, exceto veículos automotores': '30',
    },
    {
        'Fabricação de móveis': '31',
    },
    {
        'Fabricação de produtos diversos': '32',
    },
    {
        'Manutenção, reparação e instalação de máquinas e equipamentos': '33',
    },
    {
        'Eletricidade, gás e outras utilidades': '35',
    },
    {
        'Captação, tratamento e distribuição de água': '36',
    },
    {
        'Esgoto e atividades relacionadas': '37',
    },
    {
        'Coleta, tratamento e disposição de resíduos; recuperação de materiais': '38',
    },
    {
        'Descontaminação e outros serviços de gestão de resíduos': '39',
    },
    {
        'Construção de edifícios': '41',
    },
    {
        'Obras de infra-estrutura': '42',
    },
    {
        'Serviços especializados para construção': '43',
    },
    {
        'Comércio e reparação de veículos automotores e motocicletas': '45',
    },
    {
        'Comércio por atacado: exceto veículos automotores e motocicletas': '46',
    },
    {
        'Comércio varejista': '47',
    },
    {
        'Transporte terrestre': '49',
    },
    {
        'Transporte aquaviário': '50',
    },
    {
        'Transporte aéreo': '51',
    },
    {
        'Armazenamento e atividades auxiliares dos transportes': '52',
    },
    {
        'Correio e outras atividades de entrega': '53',
    },
    {
        Alojamento: '55',
    },
    {
        Alimentação: '56',
    },
    {
        'Edição e edição integrada à impressão': '58',
    },
    {
        'Atividades cinematográficas, produção de vídeos e de programas de televisão; gravação de som e edição de música': '59',
    },
    {
        'Atividades de rádio e de televisão': '60',
    },
    {
        Telecomunicações: '61',
    },
    {
        'Atividades dos serviços de tecnologia da informação': '62',
    },
    {
        'Atividades de prestação de serviços de informação': '63',
    },
    {
        'Atividades de serviços financeiros': '64',
    },
    {
        'Seguros, resseguros, previdência complementar e planos de saúde': '65',
    },
    {
        'Atividades auxiliares dos serviços financeiros, seguros, previdência complementar e planos de saúde': '66',
    },
    {
        'Atividades imobiliárias': '68',
    },
    {
        'Atividades jurídicas: de contabilidade e de auditoria': '69',
    },
    {
        'Atividades de sedes de empresas e de consultoria em gestão empresarial': '70',
    },
    {
        'Serviços de arquitetura e engenharia; testes e análises técnicas': '71',
    },
    {
        'Pesquisa e desenvolvimento científico': '72',
    },
    {
        'Publicidade e pesquisa de mercado': '73',
    },
    {
        'Outras atividades profissionais, científicas e técnicas': '74',
    },
    {
        'Atividades veterinárias': '75',
    },
    {
        'Aluguéis não-imobiliários e gestão de ativos intangíveis não-financeiros': '77',
    },
    {
        'Seleção, agenciamento e locação de mão-de-obra': '78',
    },
    {
        'Agências de viagens, operadores turísticos e serviços de reservas': '79',
    },
    {
        'Atividades de vigilância, segurança e investigação': '80',
    },
    {
        'Serviços para edifícios e atividades paisagísticas': '81',
    },
    {
        'Serviços de escritório, de apoio administrativo e outros serviços prestados principalmente às empresas': '82',
    },
    {
        'Administração pública, defesa e seguridade social': '84',
    },
    {
        Educação: '85',
    },
    {
        'Atividades de atenção à saúde humana': '86',
    },
    {
        'Atividades de atenção à saúde humana integradas com assistência social, prestadas em residências coletivas e particulares': '87',
    },
    {
        'Serviços de assistência social sem alojamento': '88',
    },
    {
        'Atividades artísticas, criativas e de espetáculos': '90',
    },
    {
        'Atividades ligadas ao patrimônio cultural e ambiental': '91',
    },
    {
        'Atividades de exploração de jogos de azar e apostas': '92',
    },
    {
        'Atividades esportivas e de recreação e lazer': '93',
    },
    {
        'Atividades de organizações associativas': '94',
    },
    {
        'Reparação e manutenção de equipamentos de informática e comunicação e de objetos pessoais e domésticos': '95',
    },
    {
        'Outras atividades de serviços pessoais': '96',
    },
    {
        'Serviços domésticos': '97',
    },
    {
        'Organismos internacionais e outras instituições extraterritoriais': '99',
    },
];
