var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import ArrowRightIcon from 'src/components/WelcomeScreen/icons/ArrowRightIcon.vue';
import SpeedioIcon from 'src/components/WelcomeScreen/icons/SpeedioIcon.vue';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
let InformationBanner = class InformationBanner extends Vue {
    constructor() {
        super(...arguments);
        this.showError = true;
        this.titles = [
            '',
            'Vamos criar a lista de leads ideal para o seu negócio?',
            'Vamos criar a lista de leads ideal para o seu negócio?',
            'Vamos criar a lista de leads ideal para o seu negócio?',
            'Vamos criar a lista de leads ideal para o seu negócio?',
            'Agora é com você!',
            'Você ainda pode obter novas oportunidades',
        ];
        this.tips = [
            '',
            'O primeiro passo é escolher um setor de empresas que você quer prospectar',
            'Defina o perfil das empresas que você deseja na lista de leads',
            'Identifique o departamento dos decisores para uma abordagem mais efetiva.',
            'Suas respostas ajudam a personalizar a sua lista de leads para aumentar suas chances de vendas.',
            'Consulte os leads gerados e comece a prospectar! Você também pode criar mais listas em Filtros Avançados dentro da plataforma.',
            'Suas respostas ajudam a personalizar a busca para aumentar as chances de vendas.',
        ];
    }
    closePopup() {
        this.showError = false;
    }
    formatNumber(number) {
        if (number > 0) {
            const numberString = number.toString();
            const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return formattedNumber;
        }
    }
};
__decorate([
    Prop({ default: '' })
], InformationBanner.prototype, "userName", void 0);
__decorate([
    Prop({ default: 1 })
], InformationBanner.prototype, "page", void 0);
__decorate([
    Prop({ default: 0 })
], InformationBanner.prototype, "searchResult", void 0);
__decorate([
    Prop({ default: false })
], InformationBanner.prototype, "loading", void 0);
InformationBanner = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'InformationBanner',
        components: {
            ArrowRightIcon,
            SpeedioIcon,
            IconSpeedioSpinner,
        },
    }))
], InformationBanner);
export default InformationBanner;
