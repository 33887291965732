var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import CelphoneIcon from '@/components/WelcomeScreen/icons/CelphoneIcon.vue';
import WhatsappIcon from '@/components/WelcomeScreen/icons/WhatsappIcon.vue';
import Others from '@/components/WelcomeScreen/icons/Others.vue';
import LinkedinIcon from '@/components/WelcomeScreen/icons/LinkedinIcon.vue';
import InboundIcon from '@/components/WelcomeScreen/icons/InboundIcon.vue';
import EmailIcon from '@/components/WelcomeScreen/icons/EmailIcon.vue';
let OptionNetwork = class OptionNetwork extends Vue {
    constructor() {
        super(...arguments);
        this.optionSelected = false;
    }
    networkSelected() {
        if (!this.optionSelected && this.network) {
            this.optionSelected = false;
            return { network: this.network };
        }
        this.optionSelected = false;
        return { network: this.network };
    }
};
__decorate([
    Prop({ default: '' })
], OptionNetwork.prototype, "index", void 0);
__decorate([
    Prop({ default: '' })
], OptionNetwork.prototype, "network", void 0);
__decorate([
    Prop({ default: '' })
], OptionNetwork.prototype, "cardSelected", void 0);
__decorate([
    Emit('networkSelected')
], OptionNetwork.prototype, "networkSelected", null);
OptionNetwork = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'OptionNetwork',
        components: {
            CelphoneIcon,
            WhatsappIcon,
            Others,
            LinkedinIcon,
            InboundIcon,
            EmailIcon,
        },
    }))
], OptionNetwork);
export default OptionNetwork;
