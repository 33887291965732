var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator';
import { connect } from '@/overmind';
import IconBlueSuccess from '@/components/Icons/icon-blue-success.vue';
import IconArrowDown from '@/components/Icons/IconArrowDown.vue';
let CustumizedPortModal = class CustumizedPortModal extends Vue {
    constructor() {
        super(...arguments);
        this.showOptions = false;
        this.select = false;
        this.select2 = false;
        this.billing = [
            { value: 'R$ 1', check: false, range: 1 },
            { value: 'R$ 80.000,00', check: false, range: 1 },
            { value: 'R$ 360.000,00', check: false, range: 2 },
            { value: 'R$ 1.000.000,00', check: false, range: 3 },
            { value: 'R$ 5.000.000,00', check: false, range: 4 },
        ];
        this.billing1 = [
            { value: 'R$ 80.000,00', check: false, range: 1 },
            { value: 'R$ 360.000,00', check: false, range: 2 },
            { value: 'R$ 1.000.000,00', check: false, range: 3 },
            { value: 'R$ 5.000.000,00', check: false, range: 4 },
            { value: 'R$ +5.000.000,00', check: false, range: 10 },
        ];
        this.billingOptionsMin = 'Valor Mínimo';
        this.showOptions1 = false;
        this.employes = [
            { value: '1 Funcionários', check: false },
            { value: '5 Funcionários', check: false },
            { value: '10 Funcionários', check: false },
            { value: '50 Funcionários', check: false },
            { value: '100 Funcionários', check: false },
            { value: '500 Funcionários', check: false },
        ];
        this.select1 = false;
        this.employes1 = [
            { value: '1 Funcionários', check: false },
            { value: '5 Funcionários', check: false },
            { value: '10 Funcionários', check: false },
            { value: '50 Funcionários', check: false },
            { value: '100 Funcionários', check: false },
            { value: '500 Funcionários', check: false },
            { value: '+500 Funcionários', check: false },
        ];
        this.billingOptionsMax = 'Valor Máximo';
        this.showOptions2 = false;
        this.employeesOptionsMin = 'Qtd. Mínima';
        this.showOptions3 = false;
        this.select3 = false;
        this.employeesOptionsMax = 'Qtd. Máxima';
        this.employeesFirstValue = '';
        this.employeesLastValue = '';
        this.billingFirstValue = '';
        this.billingLastValue = '';
        this.company_size = {
            name: '',
            employees: ['', ''],
            billing: [0, 0],
        };
        this.companyAux = {
            name: '',
            employees: ['', ''],
            billing: ['', ''],
        };
    }
    mounted() {
        /*
        this.employeesFirstValue = this.company_size.employees[0]
        this.employeesLastValue = this.company_size.employees[1]
    
        this.billingFirstValue = this.billing.forEach(f => {
          if (f.range == this.company_size.billing[0]) return f.value
        })
        this.billingLastValue = this.billing.forEach(f => {
          if (f.range == this.company_size.billing[1]) return f.value
        })*/
    }
    setEmployeesRange({ employeesFirstValue, employeesLastValue, }) {
        if (employeesFirstValue) {
            this.employeesFirstValue = employeesFirstValue;
            this.showOptions2 = false;
        }
        if (employeesLastValue) {
            this.employeesLastValue = employeesLastValue;
            this.showOptions3 = false;
        }
    }
    setBillingRange({ billingFirstValue, billingLastValue, }) {
        if (billingFirstValue) {
            this.billingFirstValue = billingFirstValue;
            this.showOptions = false;
        }
        if (billingLastValue) {
            this.billingLastValue = billingLastValue;
            this.showOptions1 = false;
        }
    }
    handleBillingValues() {
        this.companyAux.billing[0] = this.billingFirstValue;
        this.companyAux.billing[1] = this.billingLastValue;
        const billingFirst = this.billing.find(b => b.value === this.billingFirstValue);
        const billingLast = this.billing1.find(b => b.value === this.billingLastValue);
        const range = [];
        if (billingFirst) {
            range.push(billingFirst.range);
        }
        if (billingLast)
            range.push(billingLast.range);
        return range;
    }
    handleEmployeesValues() {
        this.companyAux.employees[0] = this.employeesFirstValue;
        this.companyAux.employees[1] = this.employeesLastValue;
        const employeesFirst = this.employeesFirstValue.split(' ')[0] == 'Qtd.'
            ? ''
            : this.employeesFirstValue.split(' ')[0];
        const employeesLast = this.employeesLastValue.split(' ')[0] == 'Qtd.'
            ? ''
            : this.employeesLastValue.split(' ')[0];
        return [employeesFirst, employeesLast];
    }
    handleContinue() {
        if (this.employeesFirstValue == 'Qtd. Mínima' ||
            this.employeesLastValue == 'Qtd. Máxima' ||
            this.billingFirstValue == 'Valor Mínimo' ||
            this.billingLastValue == 'Valor Máximo') {
            return true;
        }
        else if (this.employeesFirstValue &&
            this.employeesLastValue &&
            this.billingFirstValue &&
            this.billingLastValue) {
            return false;
        }
        else {
            return true;
        }
    }
    sendInformation() {
        this.company_size = {
            name: 'Customizado',
            billing: this.handleBillingValues(),
            employees: this.handleEmployeesValues(),
        };
        /*this.employeesFirstValue = 'Qtd. Mínima'
        this.employeesLastValue = 'Qtd. Máxima'
        this.billingFirstValue = 'Valor Mínimo'
        this.billingLastValue = 'Valor Máximo'*/
        this.openModal = false;
        return { portSelected: this.company_size };
    }
    closeModal() {
        this.billingFirstValue = this.companyAux.billing[0];
        this.billingLastValue = this.companyAux.billing[1];
        this.employeesFirstValue = this.companyAux.employees[0];
        this.employeesLastValue = this.companyAux.employees[1];
        /* this.employeesFirstValue = 'Qtd. Mínima'
        this.employeesLastValue = 'Qtd. Máxima'
        this.billingFirstValue = 'Valor Mínimo'
        this.billingLastValue = 'Valor Máximo'*/
        this.company_size = {
            name: 'Customizado',
            billing: this.handleBillingValues(),
            employees: this.handleEmployeesValues(),
        };
        this.$emit('closeModal', { portSelected: this.company_size });
        this.openModal = false;
    }
};
__decorate([
    PropSync('show', { default: false })
], CustumizedPortModal.prototype, "openModal", void 0);
__decorate([
    Prop({ default: () => { } })
], CustumizedPortModal.prototype, "companySize", void 0);
__decorate([
    Emit('sendInformation')
], CustumizedPortModal.prototype, "sendInformation", null);
CustumizedPortModal = __decorate([
    Component(connect({
        name: 'CustumizedPortModal',
        components: {
            IconBlueSuccess,
            IconArrowDown,
        },
    }))
], CustumizedPortModal);
export default CustumizedPortModal;
