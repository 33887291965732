var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let SuccessPage = class SuccessPage extends Vue {
    constructor() {
        super(...arguments);
        this.loadingSearch = false;
    }
    mounted() {
        mixpanelTracking('welcomescreen2.0: step lista pronta foi visualizado');
        mixpanelTracking(`welcomescreen2.0: ${this.searchResult.toString()} empresas foram geradas`);
        if (this.mainAverageTicket == 'Não sei') {
            mixpanelTracking('welcomescreen2.0: não informou o ticket médio');
        }
        else {
            mixpanelTracking(`welcomescreen2.0: pipeline de vendas de ${this.formatNumber(this.mainAverageTicket, this.searchResult)}`);
        }
    }
    formatNumber(ticket, result) {
        const formatCurrency = value => {
            return value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });
        };
        if (ticket === 'até R$650,00') {
            return `até ${formatCurrency(650 * result)}`;
        }
        else if (ticket === 'de R$650,00 a R$1.000,00') {
            return `${formatCurrency(650 * result)} a ${formatCurrency(1000 * result)}`;
        }
        else if (ticket === 'de R$1.000,00 a R$2.000,00') {
            return `${formatCurrency(1000 * result)} a ${formatCurrency(2000 * result)}`;
        }
        else if (ticket === 'de R$2.000,00 a R$3.000,00') {
            return `${formatCurrency(2000 * result)} a ${formatCurrency(3000 * result)}`;
        }
        else if (ticket === 'de R$3.000,00 a R$5.000,00') {
            return `${formatCurrency(3000 * result)} a ${formatCurrency(5000 * result)}`;
        }
        else if (ticket === 'de R$5.000,00 a R$10.000,00') {
            return `${formatCurrency(5000 * result)} a ${formatCurrency(10000 * result)}`;
        }
        else if (ticket === 'de R$10.000,00 a R$20.000,00') {
            return `${formatCurrency(10000 * result)} a ${formatCurrency(20000 * result)}`;
        }
        else if (ticket === 'Acima de R$20.000,00') {
            return `acima de ${formatCurrency(20000 * result)}`;
        }
        else {
            return '';
        }
    }
    addDotsToNumber(number) {
        if (number < 1000) {
            return String(number);
        }
        const numberString = String(number);
        const parts = [];
        for (let i = numberString.length; i > 0; i -= 3) {
            //@ts-ignore
            parts.unshift(numberString.slice(Math.max(0, i - 3), i));
        }
        return parts.join('.');
    }
    async nextSearch() {
        this.loadingSearch = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.resetSearchQuery();
        const sectors = this.dataToSearch.sectors.length
            ? [{ main_cnae_group_code: this.dataToSearch.sectors }]
            : [];
        const query = {
            search: {
                match_either: sectors,
                match: {
                    'decision_makers_lkd_m5.department': [...this.dataToSearch.decisors],
                    ds_situacao_cnpj: ['ATIVA'],
                },
                offset: 0,
                return_size: 10,
                where: {
                    revenue_a32_company_code: this.dataToSearch.company_size.searchBilling,
                    total_employees_r2016_company: this.dataToSearch.company_size.searchEmployees,
                },
                wherenot: {},
                sort_by: { quality_data_score: 'desc' },
            },
            eliminate_cnpjs_already_exported_in_account: false,
            special_filter: false,
            origin: 'welcome_screen',
        };
        mixpanelTracking('welcomescreen 2.0: clicou em quero ver meus leads');
        //@ts-ignore
        const id = await this.actions.searches.welcomeScreenCreateSearch({ query });
        //@ts-ignore
        await this.actionsUsers.updateDefaultSearch({
            defaultSearch: id,
            use_default_search: true,
        });
        //@ts-ignore
        //window.location.href = this.getUrl(id)
        if (window.location.hostname.match('localhost')) {
            window.location.href = `http://localhost:8080/search/${id}`;
        }
        if (window.location.hostname.match('dev')) {
            window.location.href = `https://dev.speedio.com.br/search/${id}`;
        }
        if (window.location.hostname.match('app')) {
            window.location.href = `https://app.speedio.com.br/search/${id}`;
        }
        if (window.location.hostname.match('staging')) {
            window.location.href = `https://staging.speedio.com.br/search/${id}`;
        }
        this.loadingSearch = false;
    }
};
__decorate([
    Prop({ default: {} })
], SuccessPage.prototype, "dataToSearch", void 0);
__decorate([
    Prop({ default: 0 })
], SuccessPage.prototype, "searchResult", void 0);
__decorate([
    Prop({ default: '' })
], SuccessPage.prototype, "mainAverageTicket", void 0);
SuccessPage = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.search,
        actionsUsers: actions.users,
    }), {
        name: 'SuccessPage',
        components: {},
    }))
], SuccessPage);
export default SuccessPage;
