var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import OptionSector from '@/components/WelcomeScreen/OptionSector.vue';
import { mixpanelTracking, mixpanelUserSetData } from '@/services/mixpanel';
import { filters } from '@/components/WelcomeScreen/utils';
let ChooseSectorsPage = class ChooseSectorsPage extends Vue {
    constructor() {
        super(...arguments);
        this.filterGroup = [];
        this.searchTerm = '';
        this.filteredFilters = [];
        this.selectedSectors = [];
        this.selectedLabelsSectors = [];
    }
    mounted() {
        mixpanelTracking('welcomescreen 2.0: step seleção de setores foi visualizado');
        this.filteredFilters = filters.map(filter => Object.entries(filter)[0]);
        this.filteredFilters = this.filteredFilters.map(sector => {
            //Improve string with ; and :
            if (sector[0].match(';'))
                return [sector[0].replaceAll(';', ','), sector[1]];
            if (sector[0] ==
                'Comércio por atacado: exceto veículos automotores e motocicletas')
                return [sector[0].replaceAll(':', ','), sector[1]];
            return sector;
        });
        if (this.sectors.length) {
            this.selectedSectors.push(...this.sectors);
            return;
        }
    }
    filterSectors() {
        if (!this.searchTerm)
            return this.filteredFilters;
        return this.filteredFilters.filter(filter => {
            return filter[0].toLowerCase().match(this.searchTerm.toLowerCase());
        });
    }
    confirmAndNextPage() {
        mixpanelTracking('welcomescreen 2.0: step seleção de setores foi configurado');
        mixpanelUserSetData({
            $setor_de_interesse: this.selectedSectors.map(s => s[0]).toString(),
        });
        return {
            page: 2,
            data: {
                sectors: this.selectedSectors,
            },
        };
    }
    handleSelectSectors({ sector, selected, }) {
        if (selected) {
            this.selectedSectors.push(sector);
        }
        else {
            this.selectedSectors = this.selectedSectors.filter(s => s[0] !== sector[0]);
        }
    }
    isSelected(sector) {
        return this.selectedSectors.some(selected => selected[0] === sector[0]);
    }
};
__decorate([
    Prop({ default: () => [] })
], ChooseSectorsPage.prototype, "sectors", void 0);
__decorate([
    Emit('confirmAndNextPage')
], ChooseSectorsPage.prototype, "confirmAndNextPage", null);
ChooseSectorsPage = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'ChooseSectorsPage',
        components: {
            OptionSector,
        },
    }))
], ChooseSectorsPage);
export default ChooseSectorsPage;
