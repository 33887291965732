var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { mixpanelTracking, mixpanelUserSetData } from '@/services/mixpanel';
import AgendorIcon from '@/components/WelcomeScreen/icons/AgendorIcon.vue';
import BitrixIcon from '@/components/WelcomeScreen/icons/BitrixIcon.vue';
import HubspotIcon from '@/components/WelcomeScreen/icons/HubspotIcon.vue';
import PipedriveIcon from '@/components/WelcomeScreen/icons/PipedriveIcon.vue';
import PiperunIcon from '@/components/WelcomeScreen/icons/PiperunIcon.vue';
import PloomesIcon from '@/components/WelcomeScreen/icons/PloomesIcon.vue';
import RdIcon from '@/components/WelcomeScreen/icons/RdIcon.vue';
import SalesforceIcon from '@/components/WelcomeScreen/icons/SalesforceIcon.vue';
import TotvsIcon from '@/components/WelcomeScreen/icons/TotvsIcon.vue';
import OthersIcon from '@/components/WelcomeScreen/icons/OthersIcon.vue';
import MiniArrowIcon from '@/components/WelcomeScreen/icons/MiniArrowIcon.vue';
import BlogIcon from '@/components/WelcomeScreen/icons/BlogIcon.vue';
import GoogleIcon from '@/components/WelcomeScreen/icons/GoogleIcon.vue';
import IndicaIcon from '@/components/WelcomeScreen/icons/IndicaIcon.vue';
import InfluenciadoresIcon from '@/components/WelcomeScreen/icons/InfluenciadoresIcon.vue';
import RedesSociaisIcon from '@/components/WelcomeScreen/icons/RedesSociaisIcon.vue';
import OutrosIcon from '@/components/WelcomeScreen/icons/OutrosIcon.vue';
let ChooseContactDataPage = class ChooseContactDataPage extends Vue {
    constructor() {
        super(...arguments);
        this.companyName = '';
        this.position_in_company = '';
        this.main_product_or_service = '';
        this.main_average_ticket = '';
        this.employees_total = '';
        this.how_much_sdrs = '';
        this.use_crm = '';
        this.how_did_you_know_speedio = '';
        this.positionOptions = [
            'Coordenador de Marketing',
            'Coordenador de Vendas / Comercial / Receita',
            'Coordenador (outras áreas)',
            'Diretor de Marketing',
            'Diretor de Vendas / Comercial / Receita',
            'Diretor (outras áreas)',
            'Gerente de Marketing',
            'Gerente de Vendas / Comercial / Receita',
            'Gerente (outras áreas)',
            'Pré-vendedor',
            'Sócio / CEO / Presidente',
            'Vendedor',
            'Vice Presidente',
            'Outros',
        ];
        this.ticketOptions = [
            'até R$650,00',
            'de R$650,00 a R$1.000,00',
            'de R$1.000,00 a R$2.000,00',
            'de R$2.000,00 a R$3.000,00',
            'de R$3.000,00 a R$5.000,00',
            'de R$5.000,00 a R$10.000,00',
            'de R$10.000,00 a R$20.000,00',
            'Acima de R$20.000,00',
            'Não sei',
        ];
        this.employeesOptions = [
            'Sou o Único colaborador',
            '02 - 10 colaboradores',
            '11 - 20 colaboradores',
            '21 - 30 colaboradores',
            '31 - 50 colaboradores',
            '50 - 100 colaboradores',
            '101 - 200 colaboradores',
            '201 - 300 colaboradores',
            'Acima de 300 colaboradores',
        ];
        this.numbersSDRorBDRs = [
            'Não tenho pré-vendedor',
            'Sou o pré-vendedor',
            'Apenas 01 pré-vendedor',
            '02 - 03 pré-vendedores',
            '04-08 pré-vendedores',
            '08-15 pré-vendedores',
            'Acima de 15 pré-vendedores',
        ];
        this.CRMOptions = [
            'Agendor',
            'Bitrix',
            'Dynamics',
            'HubSpot',
            'Nectar',
            'Pipedrive',
            'PipeRun',
            'Ploomes',
            'RD Station',
            'Salesforce',
            'TOTVS',
            'Outros',
            'Não utilizamos CRM',
        ];
        this.knowOptions = [
            'Blog',
            'Busca no Google',
            'Indicação',
            'Influenciadores',
            'Redes sociais',
            'Outros',
        ];
    }
    handleBDRorSDR(option) {
        switch (option) {
            case 'Não tenho pré-vendedor':
                return '0';
            case 'Apenas 01 pré-vendedor':
                return '1';
            case '02 - 03 pré-vendedores':
                return '2 a 3';
            case '04-08 pré-vendedores':
                return '4 a 8';
            case '08-15 pré-vendedores':
                return '9 a 15';
            case 'Acima de 15 pré-vendedores':
                return '15+';
            case 'Sou o pré-vendedor':
                return 'Sou o pré-vendedor';
        }
    }
    disabledNextBtn() {
        return this.position_in_company &&
            this.how_much_sdrs &&
            this.use_crm &&
            this.employees_total &&
            this.main_product_or_service &&
            this.main_average_ticket &&
            this.how_did_you_know_speedio
            ? false
            : true;
    }
    mounted() {
        this.companyName = this.compName;
        this.position_in_company = this.positionInCompany;
        this.main_product_or_service = this.mainProductOrService;
        this.main_average_ticket = this.mainAverageTicket;
        this.employees_total = this.employeesTotal;
        this.how_much_sdrs = this.howMuchSDRs;
        this.use_crm = this.useCRM;
        this.how_did_you_know_speedio = this.howDidYouKnowSpeedio;
        mixpanelTracking('welcomescreen2.0: step queremos te conhecer foi visualizado');
    }
    selectSDRorBDRs(option) {
        if (this.how_much_sdrs === option) {
            this.how_much_sdrs = '';
            return;
        }
        this.how_much_sdrs = option;
    }
    selectCRM(option) {
        if (this.use_crm === option) {
            this.use_crm = '';
            return;
        }
        this.use_crm = option;
    }
    selectKnow(option) {
        if (this.how_did_you_know_speedio === option) {
            this.how_did_you_know_speedio = '';
            return;
        }
        this.how_did_you_know_speedio = option;
    }
    confirmAndNextPage(btn) {
        if (btn == 'back') {
            mixpanelTracking('welcomescreen 2.0: retornou para o step departamento e canais');
            return {
                page: 3,
                data: {
                    company_name: this.companyName ? this.companyName : '',
                    position_in_company: this.position_in_company
                        ? this.position_in_company
                        : '',
                    main_product_or_service: this.main_product_or_service
                        ? this.main_product_or_service
                        : '',
                    main_average_ticket: this.main_average_ticket
                        ? this.main_average_ticket
                        : '',
                    employees_total: this.employees_total ? this.employees_total : '',
                    how_much_sdrs: this.how_much_sdrs ? this.how_much_sdrs : '',
                    use_crm: this.use_crm ? this.use_crm : '',
                    how_did_you_know_speedio: this.how_did_you_know_speedio
                        ? this.how_did_you_know_speedio
                        : '',
                },
            };
        }
        if (btn == 'next') {
            mixpanelTracking('welcomescreen 2.0: step queremos te conhecer foi configurado');
            mixpanelUserSetData({
                $company_name: this.companyName,
                $cargo: this.position_in_company,
                $principal_produto_ou_servico: this.main_product_or_service,
                $ticket_medio: this.main_average_ticket,
                $faixa_de_colaboradores: this.employees_total,
                $quantos_sdrs_ou_bdrs_tem_seu_time_de_prospeccao: this.how_much_sdrs,
                $qual_e_o_crm_utilizado_por_sua_empresa: this.use_crm,
                $por_onde_conheceu_a_speedio: this.how_did_you_know_speedio,
            });
            return {
                page: 4,
                data: {
                    company_name: this.companyName,
                    position_in_company: this.position_in_company,
                    main_product_or_service: this.main_product_or_service,
                    main_average_ticket: this.main_average_ticket,
                    employees_total: this.employees_total,
                    how_much_sdrs: this.how_much_sdrs,
                    use_crm: this.use_crm,
                    how_did_you_know_speedio: this.how_did_you_know_speedio,
                },
            };
        }
    }
};
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "compName", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "positionInCompany", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "mainProductOrService", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "mainAverageTicket", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "employeesTotal", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "howMuchSDRs", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "useCRM", void 0);
__decorate([
    Prop({ default: '' })
], ChooseContactDataPage.prototype, "howDidYouKnowSpeedio", void 0);
__decorate([
    Prop({ default: true })
], ChooseContactDataPage.prototype, "loading", void 0);
__decorate([
    Emit('confirmAndNextPage')
], ChooseContactDataPage.prototype, "confirmAndNextPage", null);
ChooseContactDataPage = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'ChooseContactDataPage',
        components: {
            AgendorIcon,
            BitrixIcon,
            HubspotIcon,
            PipedriveIcon,
            PiperunIcon,
            PloomesIcon,
            RdIcon,
            SalesforceIcon,
            TotvsIcon,
            OthersIcon,
            MiniArrowIcon,
            BlogIcon,
            GoogleIcon,
            IndicaIcon,
            InfluenciadoresIcon,
            RedesSociaisIcon,
            OutrosIcon,
        },
    }))
], ChooseContactDataPage);
export default ChooseContactDataPage;
