var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { connect, overmind } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
import OptionSector from 'src/components/WelcomeScreen/OptionSector.vue';
import OptionDepartment from '@/components/WelcomeScreen/OptionDepartment.vue';
import InformationBanner from '@/components/WelcomeScreen/InformationBanner.vue';
import ChooseSectorsPage from '@/components/WelcomeScreen/page/ChooseSectorsPage.vue';
import ChooseContactDataPage from '@/components/WelcomeScreen/page/ChooseContactDataPage.vue';
import ChoosePortePage from '@/components/WelcomeScreen/page/ChoosePortePage.vue';
import ChooseDecisorsPage from '@/components/WelcomeScreen/page/ChooseDecisorsPage.vue';
import SuccessPage from '@/components/WelcomeScreen/page/SuccessPage.vue';
import FailPage from '@/components/WelcomeScreen/page/FailPage.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let WelcomeScreenPage = class WelcomeScreenPage extends Vue {
    constructor() {
        super(...arguments);
        this.count = 1;
        this.filterOptions = [];
        this.params = {};
        this.sectors = [];
        this.decisors = [];
        this.company_size = {
            name: [],
            employees: [],
            billing: [],
            searchEmployees: [],
            searchBilling: [],
        };
        this.best_way_to_contact = '';
        this.how_did_you_know_speedio = '';
        this.company_name = '';
        this.how_much_companies_contact = '';
        this.how_much_sdrs = '';
        this.sell_for = '';
        this.do_active_prospecting = '';
        this.position_in_company = '';
        this.main_product_or_service = '';
        this.main_average_ticket = '';
        this.employees_total = '';
        this.use_crm = '';
        this.searchResult = 0;
        this.loading = false;
        this.default_search = false;
    }
    async mounted() {
        this.searchResult = -5;
        overmind.reaction(({ users }) => users.welcomeScreenAnswer, answer => {
            this.sectors = answer.sectors;
            this.decisors = answer.decisors;
            //@ts-ignore
            this.company_size = answer.company_size;
            this.best_way_to_contact = answer.best_way_to_contact;
            this.company_name = answer.company_name;
            this.how_much_companies_contact = answer.how_much_companies_contact;
            this.how_much_sdrs = answer.how_much_sdrs;
            this.sell_for = answer.sell_for;
            this.do_active_prospecting = answer.do_active_prospecting;
            this.position_in_company = answer.position_in_company;
            this.main_product_or_service = answer.main_product_or_service;
            this.main_average_ticket = answer.main_average_ticket;
            this.employees_total = answer.employees_total;
            this.use_crm = answer.use_crm;
            this.how_did_you_know_speedio = answer.how_did_you_know_speedio;
        });
        // @ts-ignore
        await this.usersActions.getUserMe();
        this.mixpanelIdentifyRoute();
        //@ts-ignore
        this.usersActions.setIsOnboardingSmallFormFields(true);
        localStorage.setItem('isOnboardingSmallFormFields', 'true');
    }
    mixpanelIdentifyRoute() {
        return mixpanelTracking('welcomescreen: Visualizou a seleção de setores');
    }
    handleProgress() {
        switch (this.count) {
            case 1:
                return 0.2;
            case 2:
                return 0.4;
            case 3:
                return 0.6;
            case 4:
                return 0.8;
            case 5:
                return 1;
            case 6:
                return 0;
            default:
                return 0;
        }
    }
    findMinMaxValues(arr) {
        const flatArray = arr.flat();
        const minValue = Math.min(...flatArray);
        const maxValue = Math.max(...flatArray);
        return [minValue, maxValue];
    }
    setSectors(data) {
        this.sectors = data.data.sectors;
        this.count = data.page;
        this.getResults();
        //@ts-ignore
        this.usersActions.setWelcomeScreenAnswer({
            name: 'sectors',
            value: data.data.sectors,
        });
    }
    setCompanySize(data) {
        //@ts-ignore
        this.company_size = data.data.company_size;
        this.sell_for = data.data.sell_for;
        this.do_active_prospecting = data.data.do_active_prospecting;
        this.count = data.page;
        const answers = [
            { name: 'company_size', value: data.data.company_size },
            { name: 'sell_for', value: data.data.sell_for },
            { name: 'do_active_prospecting', value: data.data.do_active_prospecting },
        ];
        this.getResults();
        answers.forEach(answer => {
            //@ts-ignore
            this.usersActions.setWelcomeScreenAnswer(answer);
        });
    }
    setDecisors(data) {
        this.decisors = data.data.decisors;
        this.best_way_to_contact = data.data.best_way_to_contact;
        this.how_much_companies_contact = data.data.how_much_companies_contact;
        this.count = data.page;
        const answers = [
            { name: 'decisors', value: data.data.decisors },
            { name: 'best_way_to_contact', value: data.data.best_way_to_contact },
            {
                name: 'how_much_companies_contact',
                value: data.data.how_much_companies_contact,
            },
        ];
        this.getResults();
        answers.forEach(answer => {
            //@ts-ignore
            this.usersActions.setWelcomeScreenAnswer(answer);
        });
    }
    setSocialContactData(data, btn) {
        this.company_name = data.data.company_name ? data.data.company_name : '';
        this.position_in_company = data.data.position_in_company
            ? data.data.position_in_company
            : '';
        this.main_product_or_service = data.data.main_product_or_service
            ? data.data.main_product_or_service
            : '';
        //@ts-ignore
        this.main_average_ticket = data.data.main_average_ticket
            ? data.data.main_average_ticket
            : '';
        this.employees_total = data.data.employees_total
            ? data.data.employees_total
            : '';
        this.how_much_sdrs = data.data.how_much_sdrs ? data.data.how_much_sdrs : '';
        this.use_crm = data.data.use_crm ? data.data.use_crm : '';
        this.how_did_you_know_speedio = data.data.how_did_you_know_speedio
            ? data.data.how_did_you_know_speedio
            : '';
        this.count = data.page;
        const answers = [
            { name: 'company_name', value: data.data.company_name },
            { name: 'position_in_company', value: data.data.position_in_company },
            {
                name: 'main_product_or_service',
                value: data.data.main_product_or_service,
            },
            { name: 'main_average_ticket', value: data.data.main_average_ticket },
            { name: 'employees_total', value: data.data.employees_total },
            { name: 'how_much_sdrs', value: data.data.how_much_sdrs },
            { name: 'use_crm', value: data.data.use_crm },
            {
                name: 'how_did_you_know_speedio',
                value: data.data.how_did_you_know_speedio,
            },
        ];
        answers.forEach(answer => {
            //@ts-ignore
            this.usersActions.setWelcomeScreenAnswer(answer);
        });
        if (btn == 'next') {
            this.saveDataInformation();
            this.loading = false;
        }
    }
    backToPage({ page }) {
        this.count = page;
    }
    dataToSearch() {
        const handleSectors = this.sectors.map(sector => sector[1]);
        const sortedBilling = this.sortBilling();
        let minMaxEmployees = this.findMinMaxValues(this.company_size.searchEmployees);
        return {
            sectors: handleSectors,
            decisors: this.decisors,
            company_size: {
                name: this.company_size.name,
                searchEmployees: minMaxEmployees,
                searchBilling: sortedBilling,
            },
        };
    }
    async getResults() {
        this.loading = true;
        let query = {
            search: {
                match_either: this.sectors
                    ? [{ main_cnae_group_code: this.sectors.map(sector => sector[1]) }]
                    : [],
                match: {
                    //@ts-ignore
                    ds_situacao_cnpj: ['ATIVA'],
                },
                offset: 0,
                return_size: 10,
                where: {
                    revenue_a32_company_code: [1, 10],
                    total_employees_r2016_company: ['0', '10000'],
                },
                wherenot: {},
                sort_by: { quality_data_score: 'desc' },
            },
            eliminate_cnpjs_already_exported_in_account: false,
            special_filter: false,
            origin: 'welcome_screen',
        };
        if (this.decisors.length > 0) {
            //@ts-ignore
            query.search.match['decision_makers_lkd_m5.department'] = [
                ...this.decisors,
            ];
        }
        if (this.company_size.searchBilling.length) {
            query.search.where.revenue_a32_company_code = this.sortBilling();
        }
        if (this.company_size.searchEmployees.length) {
            let minMaxEmployees = this.findMinMaxValues(this.company_size.searchEmployees);
            //@ts-ignore
            query.search.where.total_employees_r2016_company = [
                //@ts-ignore
                minMaxEmployees[0],
                //@ts-ignore
                minMaxEmployees[1],
            ];
        }
        //@ts-ignore
        let finished = await this.actions.searches.getSearchResults({ query });
        if (finished) {
            this.searchResult = finished;
            this.loading = false;
        }
    }
    sortBilling() {
        let billings = [...this.company_size.searchBilling];
        billings.sort((a, b) => a[1] - b[1]);
        return [].concat(...billings);
    }
    async saveDataInformation() {
        const data = {
            sectors: this.sectors.map(sector => sector[0]),
            decisors: this.decisors,
            company_size: {
                name: this.company_size.name,
                employees: this.company_size.employees,
                billing: this.company_size.billing,
            },
            best_way_to_contact: this.best_way_to_contact,
            company_name: this.company_name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            how_much_companies_contact: this.how_much_companies_contact,
            how_much_sdrs: this.how_much_sdrs,
            sell_for: this.sell_for,
            do_active_prospecting: this.do_active_prospecting,
            position_in_company: this.position_in_company,
            main_product_or_service: this.main_product_or_service
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            main_average_ticket: this.main_average_ticket,
            employees_total: this.employees_total,
            use_crm: this.use_crm,
            how_did_you_know_speedio: this.how_did_you_know_speedio,
        };
        //@ts-ignore
        const result = await this.usersActions.welcomeScreenSearch(data);
        if (result && this.searchResult >= 1) {
            this.count = 5;
        }
        else {
            this.count = 6;
        }
        if (this.searchResult <= 1) {
            this.count = 6;
        }
    }
};
WelcomeScreenPage = __decorate([
    Component(connect(({ state: { users: usersState }, actions }) => ({
        usersActions: actions.users,
        actions: actions.search,
        currentUserName: usersState.currentUserName,
    }), {
        name: 'WelcomeScreenPage',
        components: {
            OptionSector,
            OptionDepartment,
            InformationBanner,
            ChooseSectorsPage,
            ChooseContactDataPage,
            ChoosePortePage,
            ChooseDecisorsPage,
            SuccessPage,
            FailPage,
        },
    }))
], WelcomeScreenPage);
export default WelcomeScreenPage;
