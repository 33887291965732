var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import OptionDepartment from '@/components/WelcomeScreen/OptionDepartment.vue';
import OptionNetwork from 'src/components/WelcomeScreen/OptionNetwork.vue';
import { mixpanelTracking, mixpanelUserSetData } from '@/services/mixpanel';
import MiniArrowIcon from '@/components/WelcomeScreen/icons/MiniArrowIcon.vue';
let ChooseDecisorsPage = class ChooseDecisorsPage extends Vue {
    constructor() {
        super(...arguments);
        this.cardSelected = '';
        this.best_way_to_contact = '';
        this.howManyCompanies = '';
        this.selectedDepartments = [];
        this.departaments = [
            'Administrativo',
            'Comercial',
            'RH',
            'TI',
            'Jurídico',
            'Operacional',
            'Financeiro',
            'Marketing',
            'Outros',
        ];
        this.companyOptions = [
            '0 a 100 empresas',
            '101 a 200 empresas',
            '201 a 300 empresas',
            'Mais de 300 empresas',
        ];
    }
    mounted() {
        this.howManyCompanies = this.howMuchCompanies;
        this.cardSelected = this.bestWay;
        this.best_way_to_contact = this.bestWay;
        if (this.decisors.length)
            this.selectedDepartments.push(...this.decisors);
        mixpanelTracking('welcomescreen 2.0: step departamento e canais foi visualizado');
    }
    handleSelectDepartments(departament) {
        const existSector = this.selectedDepartments.some(s => s === departament);
        if (existSector) {
            this.selectedDepartments = this.selectedDepartments.filter(s => s !== departament);
            return;
        }
        departament = departament == 'RH' ? 'Recursos Humanos' : departament;
        this.selectedDepartments.push(departament);
    }
    selected(name) {
        this.cardSelected = name;
    }
    networkSelected({ network }) {
        this.best_way_to_contact = network;
    }
    handleDisabledNextPage() {
        return (!this.selectedDepartments.length ||
            !this.best_way_to_contact ||
            !this.howManyCompanies);
    }
    confirmAndNextPage() {
        mixpanelTracking('welcomescreen 2.0: step departamento e canais foi configurado');
        mixpanelUserSetData({
            $departamento_dos_decisores_ideais: this.selectedDepartments.toString(),
            $canal_favorito_de_contato: this.best_way_to_contact,
            $quantas_empresas_contacta_por_mes: this.howManyCompanies,
        });
        return {
            page: 4,
            data: {
                decisors: this.selectedDepartments,
                best_way_to_contact: this.best_way_to_contact,
                how_much_companies_contact: this.howManyCompanies,
            },
        };
    }
    backToPage() {
        mixpanelTracking('welcomescreen 2.0: retornou para o step porte e contatos mensais');
        return { page: 2 };
    }
};
__decorate([
    Prop({ default: () => [] })
], ChooseDecisorsPage.prototype, "decisors", void 0);
__decorate([
    Prop({ default: '' })
], ChooseDecisorsPage.prototype, "bestWay", void 0);
__decorate([
    Prop({ default: '' })
], ChooseDecisorsPage.prototype, "howMuchCompanies", void 0);
__decorate([
    Emit('confirmAndNextPage')
], ChooseDecisorsPage.prototype, "confirmAndNextPage", null);
__decorate([
    Emit('backToPage')
], ChooseDecisorsPage.prototype, "backToPage", null);
ChooseDecisorsPage = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'ChooseDecisorsPage',
        components: {
            OptionDepartment,
            OptionNetwork,
            MiniArrowIcon,
        },
    }))
], ChooseDecisorsPage);
export default ChooseDecisorsPage;
