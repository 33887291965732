var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import OptionPortClient from 'src/components/WelcomeScreen/OptionPortClient.vue';
import OptionSellClient from 'src/components/WelcomeScreen/OptionSellClient.vue';
import { mixpanelTracking, mixpanelUserSetData } from '@/services/mixpanel';
import CustumizedPortModal from 'src/components/WelcomeScreen/CustumizedPortModal.vue';
let ChoosePortePage = class ChoosePortePage extends Vue {
    constructor() {
        super(...arguments);
        this.selectedPorts = [];
        this.sellCardSelected = '';
        this.disable = true;
        this.key = 1;
        this.showModal = false;
        this.showDiffError = false;
        this.showEmptyError = false;
        this.employeesMin = '';
        this.employeesMax = '';
        this.billingMin = '';
        this.billingMax = '';
        this.searchBilling = '';
        this.searchEmployees = '';
        this.sell_for = '';
        this.do_active_prospecting = '';
        this.employeesMinOptions = [
            '1 Funcionário',
            '5 Funcionários',
            '10 Funcionários',
            '50 Funcionários',
            '100 Funcionários',
            '500 Funcionários',
        ];
        this.employeesMaxOptions = [
            '5 Funcionários',
            '10 Funcionários',
            '50 Funcionários',
            '100 Funcionários',
            '500 Funcionários',
            '500+ Funcionários',
        ];
        this.billingMinOptions = [
            'R$1,00',
            'R$80.000,00',
            'R$360.000,00',
            'R$1.000.000,00',
            'R$5.000.000,00',
        ];
        this.billingMaxOptions = [
            'R$80.000,00',
            'R$360.000,00',
            'R$1.000.000,00',
            'R$5.000.000,00',
            'R$5.000.000,00+',
        ];
    }
    mounted() {
        if (this.selectedPorts)
            this.disable = false;
        this.sellCardSelected = this.sellFor;
        this.sell_for = this.sellFor;
        this.do_active_prospecting = this.doActive;
        const companyObject = {
            billing: this.companySize.billing,
            employees: this.companySize.employees,
            name: this.companySize.name,
            searchBilling: this.companySize.searchBilling,
            searchEmployees: this.companySize.searchEmployees,
        };
        const arrayLength = companyObject.billing.length;
        const transformedArray = Array.from({ length: arrayLength }, (_, index) => ({
            billing: companyObject.billing[index],
            employees: companyObject.employees[index],
            name: companyObject.name[index],
            searchBilling: companyObject.searchBilling[index],
            searchEmployees: companyObject.searchEmployees[index],
        }));
        //@ts-ignore
        this.selectedPorts = transformedArray;
        mixpanelTracking('welcomescreen 2.0: step porte e contatos mensais foi visualizado');
    }
    clickedSell(name) {
        this.sellCardSelected = name;
        if (this.sellCardSelected) {
            this.disable = false;
        }
    }
    sellSelected(sellSelected) {
        this.sell_for = sellSelected;
    }
    clickedPort(name) {
        //@ts-ignore
        const index = this.selectedPorts.indexOf(name);
        if (index != -1) {
            this.selectedPorts.splice(index, 1);
        }
    }
    portSelected(data) {
        //@ts-ignore
        this.selectedPorts.push(data);
    }
    clickedProspect(answer) {
        this.do_active_prospecting = answer;
        if (this.do_active_prospecting) {
            this.disable = false;
        }
    }
    openModal(letItShowModal) {
        this.showModal = letItShowModal;
        document.body.classList.add('no-scroll');
    }
    closeModal() {
        document.body.classList.remove('no-scroll');
        this.showModal = false;
    }
    handleModalOptions() {
        if (this.handleDisabledContinue() == 'false') {
            this.portSelected({
                name: 'Customizado',
                billing: this.billingMin + ' a ' + this.billingMax,
                employees: this.employeesMin + ' a ' + this.employeesMax,
                searchBilling: [
                    this.handleBilling(this.billingMin),
                    this.handleBilling(this.billingMax),
                ],
                searchEmployees: [
                    this.handleEmployees(this.employeesMin),
                    this.handleEmployees(this.employeesMax),
                ],
            });
            this.closeModal();
        }
        else if (this.handleDisabledContinue() == 'errorDiff') {
            this.handleError('diff');
        }
        else {
            this.handleError('empty');
        }
    }
    handleDisabledContinue() {
        if ((this.billingMinOptions.indexOf(this.billingMin) >
            this.billingMinOptions.indexOf(this.billingMax) &&
            this.billingMax != 'R$5.000.000,00+' &&
            this.billingMax != '') ||
            (this.employeesMinOptions.indexOf(this.employeesMin) >
                this.employeesMinOptions.indexOf(this.employeesMax) &&
                this.employeesMax != '500+ Funcionários' &&
                this.employeesMax != '')) {
            return 'errorDiff';
        }
        else if (this.billingMin == '' ||
            this.billingMax == '' ||
            this.employeesMin == '' ||
            this.employeesMax == '') {
            return 'errorEmpty';
        }
        else {
            return 'false';
        }
    }
    handleDisabledNextPage() {
        if (this.sell_for == '' ||
            this.do_active_prospecting == '' ||
            this.selectedPorts.length == 0) {
            return true;
        }
        else {
            return false;
        }
    }
    handleError(type) {
        if (type == 'diff') {
            this.showEmptyError = false;
            this.showDiffError = true;
            setTimeout(() => {
                this.showDiffError = true;
            }, 5000);
        }
        else {
            this.showEmptyError = true;
            this.showDiffError = false;
            setTimeout(() => {
                this.showEmptyError = true;
            }, 5000);
        }
    }
    handleBilling(billing) {
        if (billing == 'R$1,00') {
            return 1;
        }
        else if (billing == 'R$80.000,00') {
            return 2;
        }
        else if (billing == 'R$360.000,00') {
            return 3;
        }
        else if (billing == 'R$1.000.000,00') {
            return 4;
        }
        else if (billing == 'R$5.000.000,00') {
            return 5;
        }
        else if (billing == 'R$5.000.000,00+') {
            return 10;
        }
    }
    handleEmployees(employees) {
        if (employees == '1 Funcionário') {
            return 1;
        }
        else if (employees == '5 Funcionários') {
            return 5;
        }
        else if (employees == '10 Funcionários') {
            return 10;
        }
        else if (employees == '50 Funcionários') {
            return 50;
        }
        else if (employees == '100 Funcionários') {
            return 100;
        }
        else if (employees == '500 Funcionários') {
            return 500;
        }
        else if (employees == '500+ Funcionários') {
            return 10000;
        }
    }
    confirmAndNextPage() {
        if (this.disable == false) {
            mixpanelUserSetData({
                $vende_para: this.sell_for,
                $faz_prospeccao__sdr__: this.do_active_prospecting,
                //@ts-ignore
                $porte_dos_clientes: this.selectedPorts
                    //@ts-ignore
                    .map(port => port.name)
                    .toString(),
            });
            mixpanelTracking('welcomescreen 2.0: step porte e contatos mensais foi configurado');
            return {
                page: 3,
                data: {
                    company_size: {
                        //@ts-ignore
                        name: this.selectedPorts.map(port => port.name),
                        //@ts-ignore
                        employees: this.selectedPorts.map(port => port.employees),
                        //@ts-ignore
                        billing: this.selectedPorts.map(port => port.billing),
                        //@ts-ignore
                        searchEmployees: this.selectedPorts.map(
                        //@ts-ignore
                        port => port.searchEmployees),
                        //@ts-ignore
                        searchBilling: this.selectedPorts.map(port => port.searchBilling),
                    },
                    sell_for: this.sell_for,
                    do_active_prospecting: this.do_active_prospecting,
                },
            };
        }
    }
    backToPage() {
        mixpanelTracking('welcomescreen 2.0: retornou para o step setores');
        return { page: 1 };
    }
};
__decorate([
    Prop({ default: () => { } })
], ChoosePortePage.prototype, "companySize", void 0);
__decorate([
    Prop({ default: '' })
], ChoosePortePage.prototype, "sellFor", void 0);
__decorate([
    Prop({ default: '' })
], ChoosePortePage.prototype, "doActive", void 0);
__decorate([
    Emit('confirmAndNextPage')
], ChoosePortePage.prototype, "confirmAndNextPage", null);
__decorate([
    Emit('backToPage')
], ChoosePortePage.prototype, "backToPage", null);
ChoosePortePage = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'ChoosePortePage',
        components: {
            OptionPortClient,
            CustumizedPortModal,
            OptionSellClient,
        },
    }))
], ChoosePortePage);
export default ChoosePortePage;
